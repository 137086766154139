(function () {
  "use strict";

  var imageBase = "https://res.cloudinary.com/delivery-com/image/fetch/https%3A%2F%2Fs3.amazonaws.com%2Fs3.delivery.com%2FPartnerButtons%2Fv2%2Fdcom-partner-";
  var anchorPadding = "20px";
  var anchorDisplay = "inline-block";
  var boxShadowValue = "0 0 5px 0 rgba(0,0,0,0.75)";
  var longImageStyle = {
    "border-radius": "3px",
    "box-shadow": boxShadowValue,
    "-webkit-box-shadow": boxShadowValue,
    "-moz-box-shadow": boxShadowValue,
    width: "280px",
    height: "56px"
  };
  var shortImageStyle = {
    width: "147px",
    height: "82px"
  };
  var imageMap = {
    "default": {
      src: "".concat(imageBase, "logo.png"),
      style: shortImageStyle
    },
    "dcom-merchant-button-short-white": {
      src: "".concat(imageBase, "logoAlt.png"),
      style: shortImageStyle
    },
    "dcom-merchant-button-long-blue": {
      src: "".concat(imageBase, "blue.png"),
      style: longImageStyle
    },
    "dcom-merchant-button-long-white": {
      src: "".concat(imageBase, "white.png"),
      style: longImageStyle
    },
    "dcom-merchant-button-short-blue": {
      src: "".concat(imageBase, "logo.png"),
      style: shortImageStyle
    }
  };
  function createCTA(parentNode, merchantSlug, isLaundry) {
    addAttributesToAnchor(parentNode, merchantSlug, isLaundry);
    var image = createImage(parentNode.classList);
    parentNode.appendChild(image);
  }
  function addAttributesToAnchor(anchor, merchantSlug, isLaundry) {
    anchor.href = "/merchants/".concat(merchantSlug, "?dcom_button_redirect=true").concat(isLaundry ? "&laundry=true" : "");
    anchor.style.padding = anchorPadding;
    anchor.style.display = anchorDisplay;
    return anchor;
  }
  function createImage(classList) {
    var image = document.createElement("img");
    var imageOptions = getImageOptions(classList);
    image.src = imageOptions.src;
    image.alt = "Order with Delivery.com";
    Object.keys(imageOptions.style).forEach(function (property) {
      image.style[property] = imageOptions.style[property];
    });
    return image;
  }
  function getImageOptions(classList) {
    var dcomClasses = Object.keys(classList).reduce(function (result, index) {
      var className = classList[index];
      if (className.indexOf("dcom-") > -1) {
        result.push(className);
      }
      return result;
    }, []);
    return imageMap[dcomClasses[1]] || imageMap["default"];
  }
  function init() {
    var parentNodes = document.querySelectorAll(".dcom-merchant-button");
    var scriptTag = document.getElementById("dcom-button");
    var merchantSlug = scriptTag.getAttribute("merchant");
    if (!merchantSlug) {
      // should maybe log an error or such
      console.error("Sorry, it appears that a merchant property is missing. Check your delivery.com button script or reach out to support for further assistance.");
      return;
    }
    var isLaundry = !!scriptTag.getAttribute("laundry");
    Array.prototype.forEach.call(parentNodes, function (parentNode) {
      return createCTA(parentNode, merchantSlug, isLaundry);
    });
  }
  init();
})();